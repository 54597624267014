<template>
  <!-- 客户账期审批-部门审批列表 -->
  <div class="finance-invoice-review">
    <en-table-layout ref="enTableLayout" :tableData="tableData.data">
      <template slot="header">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="待审批" name="1"></el-tab-pane>
          <el-tab-pane label="已审批" name="2"></el-tab-pane>
        </el-tabs>
        <div style="padding:10px 15px">
          <h3>客户账期申请流程：</h3>
          <div class="headerStep">
            <p class="stepImage">
              <img src="../../../assets/step/step1.png" alt="">
              <span>销售人员从【客户档案】处提交账期申请</span>
            </p>
            <p class="stepLine">
              <span class="line"></span>
              <span class="arrow-right"></span>
            </p>
            <p class="stepImage">
              <img style="width:66px" src="../../../assets/step/step2.png" alt="">
              <span>部门进行初审（默认审核通过）</span>
            </p>
            <p class="stepLine">
              <span class="line"></span>
              <span class="arrow-right"></span>
            </p>
            <p class="stepImage">
              <img style="width:92px" src="../../../assets/step/step3.png" alt="">
              <span>财务人员进行复审，并设置账期信息</span>
            </p>
            <p class="stepLine">
              <span class="line"></span>
              <span class="arrow-right"></span>
            </p>
            <p class="stepImage">
              <img src="../../../assets/step/step4.png" alt="">
              <span>客户账期审批通过</span>
            </p>
          </div>
        </div>
      </template>
      <template slot="toolbar">
        <el-form-item label="业务类型" class="col-auto">
          <el-select style="width: 110px" v-model="params.apply_type" placeholder="请选择" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="activeName == '2'" label="审批状态" class="col-auto">
          <el-select style="width: 110px" v-model="params.apply_status" placeholder="请选择" clearable>
            <el-option v-for="item in optionsTypes" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>

        <el-form-item v-if="activeName == '2'" label="时间范围" class="col-auto">
          <el-date-picker v-model="order_time_range" type="daterange" style="width: 250px" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="handleDateChange" />
        </el-form-item>
        <el-form-item>
          <el-input style="width: 290px;" size="small" placeholder="请输入关键字进行搜索" v-model.trim="params.keyWord" clearable>
            <el-select v-model="key_word" slot="prepend" style="width: 100px;">
              <el-option label="客户名称" value="customer_name"></el-option>
              <el-option label="提交人" value="dept_audit_user_name"></el-option>
              <!-- <el-option v-if="activeName == '2'" label="部门审批人" value="card_value"></el-option> -->
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button native-type="submit" @click.stop.prevent="searchEvent" size="small" type="primary">搜索</el-button>
        </el-form-item>
      </template>

      <template slot="table-columns">
        <el-table-column key="customer_name" prop="customer_name" show-overflow-tooltip label="所属客户名称" min-width="200"
          fixed="left" />
        <el-table-column key="apply_type" prop="apply_type" show-overflow-tooltip label="业务类型" min-width="150"
          fixed="left">
          <template slot-scope="{row}">{{ row.apply_type == 1 ? '账期申请' : '账期信息变更' }}</template>
        </el-table-column>
        <el-table-column key="create_time" prop="create_time" show-overflow-tooltip label="提交时间" width="160"
          fixed="left">
          <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column key="dept_audit_time" v-if="activeName == '2'" show-overflow-tooltip prop="dept_audit_time"
          label="部门审批时间" width="160">
          <template slot-scope="scope">{{ scope.row.dept_audit_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column key="dept_audit_user_name" show-overflow-tooltip prop="dept_audit_user_name" label="提交人" />
        <el-table-column key="bmspr" show-overflow-tooltip v-if="activeName == '2'" label="部门审批人" width="200">
          <template>——</template>
        </el-table-column>
        <el-table-column key="spzt" show-overflow-tooltip prop="status" label="审批状态" width="150">
          <template slot-scope="scope">
            {{ scope.row.apply_status | statusFilter }}
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column key="operation" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="openDetail(scope.row)">详情
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>

    <!--账期申请详情 dialog-->
    <x-dialog :proxy="lookDialog">
      <el-form class="orderDetail" style="padding:20px" :model="orderDetail" label-width="120px">
        <el-form-item label="客户名称" prop="pass">
          {{ orderDetail.customer_name }}
        </el-form-item>
        <el-form-item label="账期类型" prop="pass">
          {{ orderDetail.account_period ? '有账期' : '无账期' }}
        </el-form-item>
        <el-form-item label="账期信用额度" prop="pass">
          {{ orderDetail.account_period_credit_limit }}
        </el-form-item>
        <el-form-item label="账期结算方式" prop="pass">
          {{ orderDetail.account_period_settlement_method == 1 ? '按天' : '按月' }}
        </el-form-item>
        <el-form-item label="结算周期" prop="pass">
          {{ orderDetail.account_period_settlement_cycle }}
          {{ orderDetail.account_period_settlement_method == 1 ? '天' : '月' }}
        </el-form-item>
        <el-form-item label="账期可延期天数" prop="pass">
          {{ orderDetail.account_period_extended_day }}
        </el-form-item>
        <el-form-item label="备注信息" prop="pass">
          {{ orderDetail.remark }}
        </el-form-item>
        <el-form-item label="审核状态" prop="pass">
          {{ orderDetail.apply_status | statusFilter }}
        </el-form-item>
        <el-form-item label="拒绝原因" prop="pass" v-if="orderDetail.apply_status == 4">
          {{ orderDetail.apply_audit_reject_remark }}
        </el-form-item>
      </el-form>
    </x-dialog>
  </div>
</template>

<script>
import * as API_settingCustomer from '@/api/settingCustomer';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
export default {
  name: 'goodsAudit',
  components: { EnTableLayout, XDialog, },
  data () {
    return {
      activeName: '1',
      //查看操作
      lookDialog: $xDialog.create({
        title: '账单申请',
        wrapperClass: 'show-withdrawal-info',
        width: '45vw',
        disableCancel: true,
        disableConfirm: true
      }),
      // 账单申请详情
      orderDetail: {},
      options: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 1,
          label: '账期申请'
        },
        {
          value: 2,
          label: '账期信息变更'
        }
      ],
      optionsTypes: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 1,
          label: '待财务复审'
        },
        {
          value: 3,
          label: '财务审批完成'
        },
        {
          value: 4,
          label: '财务复审拒绝'
        }
      ],
      order_time_range: [],
      // 列表loading状态
      loading: false,
      key_word: 'customer_name',
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        apply_type: '',
        keyWord: "",
        apply_status: ''
      },
      // 商品数据
      tableData: {
        data: []
      },
    };
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        0: '待审核',
        1: '待财务复审',
        2: '部门审核拒绝',
        3: '财务复审通过',
        4: '财务复审拒绝',
      }
      return statusMap[status]
    }
  },
  mounted () {
    this.GET_GoodsAuditList();
  },
  methods: {
    //格式化时间控件
    handleDateChange () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.create_time_start =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.create_time_end =
            new Date(this.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.create_time_start =
            this.order_time_range[0].getTime() / 1000;
          this.params.create_time_end =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.create_time_start = "";
        this.params.create_time_end = "";
      }
    },
    handleClick () {
      this.tableData = { data: [] }
      if (this.activeName == '2') {
        this.GET_GoodsAuditList();
      }
      this.$nextTick(res => {
        this.$refs.enTableLayout.$refs.table.doLayout();
      })
    },
    /**查看 */
    openDetail (row) {
      API_settingCustomer.getCustomerCapaDetail(row.id).then(res => {
        this.orderDetail = res
        this.lookDialog.display();
      })

    },
    /** 搜索事件触发 */
    searchEvent (data) {
      this.params.page_no = 1;
      this.GET_GoodsAuditList();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_GoodsAuditList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_GoodsAuditList();
    },

    /** 获取列表数据 */
    GET_GoodsAuditList () {
      if (this.activeName == '1') return
      this.loading = true;
      let params = {
        ...this.params,
      };
      params[this.key_word] = params.keyWord
      delete params.keyWord
      if (params.apply_status == '') params.apply_status = 5
      API_settingCustomer.getCustomerPageList(params)
        .then(response => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => (this.loading = false));
    },
  }
};
</script>

<style lang="scss" scoped>
.goods-image {
  width: 50px;
  height: 50px;
}

.headerStep {
  display: flex;

  p {
    margin: 0
  }

  .stepImage {
    display: flex;
    flex-direction: column;
    width: 200px;
    justify-content: center;
    align-items: center;

    img {
      width: 70px;
    }

    span {
      margin-top: 15px;
    }
  }

  .stepLine {
    display: flex;
    align-items: center;
    margin: 0 10px 39px;

    .line {
      display: inline-block;
      width: 80px;
      height: 4px;
      background: #333;
    }

    .arrow-right {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 10px solid #333;
    }
  }
}

.orderDetail {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
